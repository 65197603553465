import { render, staticRenderFns } from "./LocationPicker.html?vue&type=template&id=c2b8b240&scoped=true&external"
import script from "./LocationPicker.js?vue&type=script&lang=js&external"
export * from "./LocationPicker.js?vue&type=script&lang=js&external"
import style0 from "./LocationPicker.scss?vue&type=style&index=0&id=c2b8b240&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b8b240",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FLocations%2FLocationPicker%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports