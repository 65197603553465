import ImageUpload from '@/components/ImageUpload/index.vue';
import States from '@/helpers/states.js'
import Geocoder from '@/helpers/maps/geocoder';
import {TheMask} from 'vue-the-mask'
import Validations from './Validations'

export default {
    name: 'location-picker',

    components: {
        ImageUpload,
        TheMask
    },

    props: {
        onAddLocation: Function,
        editing: Boolean,
        index: Number,
        beforeLocation: Object,
        errors: Object,
        valid: Function
    },

    validations: Validations,

    data() {
        return {
            countries: [
                {value: 'US', text: 'United States'}
            ],
            states: States,
            addressResult: [],

            location: {
                Name: '',
                Phone: '',
                Email: '',
                Photo: '',
                Address: '',
                City: '',
                Zip: '',
                State: "",
                Country: "",
                Longitude: 0,
                Latitude: 0,
                Domain: '',
                Hours: {
                    Sun: {
                        Enabled: false,
                        Start: "16",
                        End: "36"
                    },
                    Mon: {
                        Enabled: true,
                        Start: "16",
                        End: "36"
                    }, Tue: {
                        Enabled: true,
                        Start: "16",
                        End: "36"
                    },
                    Wed: {
                        Enabled: true,
                        Start: "16",
                        End: "36"
                    },
                    Thu: {
                        Enabled: true,
                        Start: "16",
                        End: "36"
                    },
                    Fri: {
                        Enabled: true,
                        Start: "16",
                        End: "36"
                    },
                    Sat: {
                        Enabled: true,
                        Start: "16",
                        End: "36"
                    },
                }
            }
        }
    },

    computed: {
        gotAddress() {
            return this.location.Address && this.location.Address.length > 0
                && this.location.City && this.location.City.length > 0
                && this.location.Zip && this.location.Zip.toString().length > 0
                && this.location.State && this.location.State.length > 0
                && this.location.Country && this.location.Country.length > 0;
        },

        generatedAddress() {
            const location = this.location;
            return `${location.Address}, ${location.Zip}`;
        },

        position() {
            if (!this.gotAddress || !this.addressResult.length) return {
                lat: 0.0,
                lng: 0.0
            };

            return {
                lat: this.addressResult[0].geometry.location.lat,
                lng: this.addressResult[0].geometry.location.lng
            }
        }
    },

    mounted() {
        if (this.beforeLocation)
            this.location = this.beforeLocation;

        this.addressChange();
    },

    methods: {
        onImageUpload(image) {
            this.location.Photo = image.Name;
            this.save();
        },

        addressChange() {
            if (this.gotAddress)
                this.searchLocation();
        },

        searchLocation() {
            const geocoder = new Geocoder({
                defaultCountryCode: this.Country,
                defaultMode: 'address'
            });

            const addressObj = {
                address_line_1: this.location.Address,
                address_line_2: '',
                city: this.location.City,
                state: this.location.State,
                zip_code: this.location.Zip ? parseInt(this.location.Zip) : null,
            };

            geocoder.send(addressObj, response => {
                if (response.status === 'OK') {
                    this.addressResult = response.results;
                    this.location.Longitude = this.position.lng;
                    this.location.Latitude = this.position.lat;
                    this.save();
                }
            });
        },

        changeMarker(position) {
            this.location.Longitude = position.latLng.lng();
            this.location.Latitude = position.latLng.lat();
            this.save();
        },

        save() {
            if (this.valid) {
                this.valid(!this.$v.$invalid)
            }
            if (this.location.Name.length > 0
                && this.location.Phone.length > 0
                && this.location.Email.length > 0
                && this.location.Address.length > 0
                && this.location.City.length > 0
                && this.location.Zip.length > 0
                && this.location.State.length > 0
                && this.location.Country.length > 0
                && this.location.Longitude !== 0
                && this.location.Latitude !== 0) {
                if (this.onAddLocation) {
                    this.onAddLocation(this.location);
                }
            }
        }
    }
}
