import States from '@/helpers/states.js'

import ColorPicker from "@/components/ColorPicker/index";
import ImageUpload from '@/components/ImageUpload/index.vue';
import LocationPicker from "@/components/Locations/LocationPicker/index";
import LocationHours from "@/components/Locations/LocationHours/index";

import LocationService from '@/services/location.service';

const service = new LocationService();

export default {
    name: "LocationList",

    components: {
        ColorPicker,
        LocationPicker,
        LocationHours,
        ImageUpload
    },

    data() {
        return {
            locations: [],
            location: {},

            //Modals
            pickerModal: false,
            hoursModal: false,
            removeModal: false,
            messageModal: false,
            isLoading: false,

            // LocationPicker
            editLocation: null,
            isLocationValid: false,
            locationErrors: {},

            // Review Message
            reloadLinks: -1,
            isReviewValid: false,
            defaultSocials: [
                {Name: 'Google', Url: 'https://www.google.com/', Color: ''},
                {Name: 'Facebook', Url: 'https://www.facebok.com/', Color: ''},
                {Name: 'Twitter', Url: 'https://www.twitter.com/', Color: ''},
                {Name: 'Instagram', Url: 'https://www.instagram.com/', Color: ''},
                {Name: 'CarGurus', Url: 'https://www.cargurus.com/', Color: ''},
            ],
            countries: [
                {value: 'US', text: 'United States'}
            ],
            states: States,
            SocialUrls: [],
            ReviewSms: [
                {
                    Lang: "EN",
                    SmsTemplate: "Hi, {{customer_name}}! Thank you for choosing {{location_name}}. Your feedback is incredibly important to us. The link below makes it easy:"
                },
                {
                    Lang: "ES",
                    SmsTemplate: "Hola, {{customer_name}}! Gracias por escoger {{location_name}}. Sus comentarios son increíblemente importantes para nosotros. El siguiente enlace lo hace fácil:"
                }
            ],
            ReviewImage: [
                {Lang: "EN", Photo: ""},
                {Lang: "ES", Photo: ""}
            ],

            currentPage: 1,
            perPage: 25,
            totalRows: 0,
        }
    },

    async mounted() {
        await this.list();
    },

    methods: {
        // Location Hours
        async confirmSetHours() {
            const res = await service.edit(this.location);
            if (res && !res.error) {
                this.$notify({
                    type: 'success',
                    message: this.$t('hours_updated')
                });

                this.closeHoursModal();
            }
        },
        closeHoursModal() {
            this.editLocation = null;
            this.location = {};
            this.hoursModal = false;
        },
        editHours(location, index) {
            this.hoursModal = true;
            this.location = location;
            this.editLocation = index;
        },
        onUpdateHours(hours) {
            this.locations[this.editLocation].Hours = hours;
        },

        // Location Picker
        validLocation(value) {
            this.isLocationValid = value;
        },
        async editPickerModal(location, index) {
            this.editLocation = index;
            this.pickerModal = true;
            this.location = location;
        },
        closePickerModal() {
            this.pickerModal = false;
            this.isLocationValid = false;
            this.isLoading = false;
            this.editLocation = null;
            this.location = {};
        },
        onUpdateLocation(location) {
            this.location = location;
        },

        // Location Messages
        async editMsgModal(location) {
            this.location = location;

            if (!location.ReviewImage.length)
                this.location.ReviewImage = [...this.ReviewImage];

            if (!location.SocialUrls.length)
                this.location.SocialUrls = [...this.SocialUrls];

            if (!location.ReviewSms.length)
                this.location.ReviewSms = [...this.ReviewSms];

            this.validReview();

            this.messageModal = true;
        },
        closeMsgModal() {
            this.messageModal = false;
            this.location = {};
            this.ReviewImage = [{Lang: "EN", Photo: ""}, {Lang: "ES", Photo: ""}];
            this.SocialUrls = [];
            this.ReviewSms = [
                {
                    Lang: "EN",
                    SmsTemplate: "Hi, {{customer_name}}! Thank you for choosing {{location_name}}. Your feedback is incredibly important to us. The link below makes it easy:"
                },
                {
                    Lang: "ES",
                    SmsTemplate: "Hola, {{customer_name}}! Gracias por escoger {{location_name}}. Sus comentarios son increíblemente importantes para nosotros. El siguiente enlace lo hace fácil:"
                }
            ];
            this.isLoading = false;
        },
        onSmsImageUploadEn(image) {
            this.location.ReviewImage[0].Photo = image.Name;
            this.validReview();
        },
        onSmsImageUploadEs(image) {
            this.location.ReviewImage[1].Photo = image.Name;
            this.validReview();
        },
        addSocialLink() {
            const
                index = this.location.SocialUrls.length - 1,
                value = this.location.SocialUrls[index];

            const addValue = !value || (value.Name.length && value.Url.length && value.Color.length);

            if (addValue) {
                this.location.SocialUrls.push({Name: "", Url: "", Color: ""});
            }

            this.validReview();
        },
        setSocialLink(value, index, color) {
            if (color) value.Color = color;
            this.location.SocialUrls[index] = value;
            this.reloadLinks++;
            this.validReview();
        },
        removeSocialLink(index) {
            this.location.SocialUrls.splice(index, 1);
            this.validReview();
        },
        validReview() {
            let isValid = true;

            this.location.ReviewImage.map(e => {
                if (!e.Photo.length) isValid = false;
            });

            if (!this.location.SocialUrls.length) isValid = false;
            else this.location.SocialUrls.map(e => {
                if (!e.Name.length || !e.Url.length || !e.Color.length) isValid = false;
            });

            this.location.ReviewSms.map(e => {
                if (!e.SmsTemplate.length) isValid = false;
            });

            this.isReviewValid = isValid;
        },
        async editReview() {
            this.validReview();
            if (!this.isReviewValid) {
                return;
            }

            this.isLoading = true;

            const res = await service.edit(this.location);
            if (res && !res.error) {
                this.closeMsgModal();
                this.$notify({type: 'success', message: this.$t('location_edited')});
            }
        },

        // Location Remove
        toggleRemove(id) {
            this.removeModal = !this.removeModal;
            this.removeModal ? this.location._id = id : delete this.location._id;
        },

        // CRUD
        async list(page) {
            this.currentPage = page || 1;

            const skip = this.perPage * (this.currentPage - 1);
            const res = await service.list(this.perPage, skip);
            if (res && !res.error) {
                this.locations = res.data.locations;
                this.totalRows = res.data.count;
            }
        },
        async create() {
            this.isLoading = true;

            const res = await service.create(this.location);
            if (res && !res.error) {
                this.locations.push(res.data.location);
                this.closePickerModal();
                this.$notify({type: 'success', message: this.$t('location_created')});
            }
        },
        async edit() {
            this.isLoading = true;

            const res = await service.edit(this.location);
            if (res && !res.error) {
                this.closePickerModal();
                this.$notify({type: 'success', message: this.$t('location_edited')});
            }
        },
        async remove() {
            const res = await service.delete(this.location._id);
            if (res && !res.error) {
                const index = this.locations.findIndex(loc => loc._id === this.location._id);
                if (index > -1) {
                    this.totalRows--;
                    this.locations.splice(index, 1);
                    this.toggleRemove();
                }
                this.$notify({
                    type: 'success',
                    message: this.$t('location_removed')
                });
            }
        },
    },

    filters: {
        normalizeAddress(location) {
            const parts = ["Address", "City", "State", "Zip"];

            let address = "";

            for (const part of parts) {
                if (location[part]) {
                    if (part === "City") address = address.concat(", " + location[part] + ", ");
                    else address = address.concat(location[part] + " ");
                }
            }

            return address || "N/A";
        },

        normalizePhone(phone) {
            if (!phone) return "N/A";
            return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        },
    }
};