import {required} from 'vuelidate/lib/validators';

const isName = val => {
    if (val !== "")
        return /^[A-Za-z0-9\s]+$/i.test(val);
    return true;
};
const isPhone = val => {
    if (val !== "")
        return /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
    return true;
}
const isEmail = val => {
    if (val && val !== "")
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(val);
    return true;
}
const isDomain = val => {
    if (val !== "")
        return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(val);
    return true;
}

const isCountry = val => {
    if (val !== "")
        return /^[A-Za-z\s]+$/i.test(val);
    return true;
}
const isAddress = val => {
    if (val !== "") return /^[A-Za-z0-9\s]+$/i.test(val);
    return true;
}
const isCity = val => {
    if (val !== "") return /^[A-Za-z\s]+$/i.test(val);
    return true;
}
const isState = val => {
    if (val !== "") return /^[A-Za-z\s]+$/i.test(val);
    return true;
}
const isZip = val => {
    if (val !== "") return /^[0-9]{5}/.test(val);
    return true;
}

export default {
    location: {
        Name: {required, isName},
        Phone: {required, isPhone},
        Email: {required, isEmail},
        Domain: {required, isDomain},
        Country: {required, isCountry},
        Address: {required, isAddress},
        City: {required, isCity},
        State: {required, isState},
        Zip: {required, isZip},
        Photo: {required}
    }
};